
.tf-snack {
    position: fixed;
    opacity: 0.96;
    bottom: 8px;
    right: 8px;
    border: 1px solid #f2f2f2;
    background: #7ed6ff;
    font-size: 18px;
    width: 49%;
    min-height: 140px;
    padding: 0;
    z-index: 200;
    border-radius: 4px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.tf-snack .tf-snack-label {
    padding: 16px;
    display: block;
    color: #222;
    font-size: 22px;
    font-weight: bold;
}

.tf-snack .tf-snack-text {
    display: block;
    padding: 0 16px 16px;
    font-size: 18px;
    color: #444;
}

.tf-snack .tf-snack-x {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: -2px;
    font-size: 24px;
    color: #f0506e;
    font-weight: bold;
}