.tf-snack {
  opacity: .96;
  width: 49%;
  min-height: 140px;
  z-index: 200;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background: #7ed6ff;
  border: 1px solid #ffffff4d;
  border-radius: 4px;
  padding: 0;
  font-size: 18px;
  position: fixed;
  bottom: 8px;
  right: 8px;
  box-shadow: 0 4px 30px #0000001a;
}

.tf-snack .tf-snack-label {
  color: #222;
  padding: 16px;
  font-size: 22px;
  font-weight: bold;
  display: block;
}

.tf-snack .tf-snack-text {
  color: #444;
  padding: 0 16px 16px;
  font-size: 18px;
  display: block;
}

.tf-snack .tf-snack-x {
  cursor: pointer;
  color: #f0506e;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  top: -2px;
  right: 10px;
}

/*# sourceMappingURL=index.9a4870ce.css.map */
